
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({ name: "ClassThumbnail" })
export default class extends Vue {
  @Prop() course!: any;
  closestDate = '';

  mounted() {
    const sortedMeetings = this.course.meetings.sort((a: any, b: any) => new Date(a).valueOf() - new Date(b).valueOf()) as any[];
    const closestDate = sortedMeetings.reduce((agg: string, cur: any) => {
      const aggDate = new Date(agg).valueOf();

      if (aggDate > Date.now()) {
        return aggDate;
      }
      
      return cur.date; 
    }, sortedMeetings[0].date);

    this.closestDate = closestDate;
  }
}
